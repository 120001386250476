 /** SASS VARIABLES **/
$bjc-blue: #00529B;
$opensans: 'Open Sans', sans-serif;
$background-gray: #F8F7FA;
$black: #1A051D;
$light-blue: #97B8D6;
$gray: #666666;
$purple: #7100ab;
$fontawesome: "Font Awesome 5 Pro";

* {
  font-family: $opensans;
}

body {
  background: $background-gray;
}

body.menu-active {
  overflow-y: hidden;
}

main {
  border: 0px;
  background: $background-gray;
}

:focus {
  outline: none !important;
}

@media (max-width: 991px) {
  .container {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

h1,h2,h3,h4,h5 {
  color: $black;
  font-family: $opensans;
  line-height: 22px;
}

p {
  font-family: $opensans;
  font-size: 14px;
  line-height: 28px;
  color: $black;
}

ul, ol {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: inherit;
  margin-left: 30px;
}

ul li,
ol li {
  margin-bottom: 5px;
}

#dnnContentPane {
  padding-left: 0px;
  padding-right: 0px;
}

.clear-fix,
.clear {
  clear: both;
}

.no-padding {
  padding: 0px;
}

.relative {
  position: relative;
}

a:hover {
  text-decoration: none;
}

a.full-width-link {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

ul.searchSkinObjectPreview {
  display: none !important;
}

.desktop-header {
  display: none;
}

.mobile-header {
  display: block;
  background: $bjc-blue;
  padding-top: 45px;
  border-bottom: 0px !important;
  overflow: hidden;
  text-align: center;
  padding-bottom: 10px;

  #mobile-menu-trigger {
    color: #FFFFFF;
    font-size: 40px;
    transform: rotate(90deg) scaleX(-1);
  }

  .logo {
    padding-top: 5px;
    height: 40px;

    img {
      max-width: 240px;
      padding-top: 0px;
    }
  }

  #mobile-search-trigger {
    color: $light-blue;
    font-size: 25px;
    padding-top: 5px;
  }

  .mobile-search {
    background: $bjc-blue;
    z-index: -1;
    height: 0px;
    opacity: 0;
    transition: .3s;
    position: relative;

    .container {
      margin-left: 15px;
      margin-right: 15px;
      max-width: 500px;
      margin: 0 auto;
      position: relative;
    }

    .searchInputContainer {
      display: block;
    }

    input {
      background: #FFFFFF !important;
      display: block;
      width: 100%;
      border-radius: 20px;
      font-size: 14px;
      border: 0px;
      height: 35px;
      padding-left: 20px;
    }

    #dnn_ucHeader_dnnSearchMobile_cmdSearch {
      font-size: 20px;
      width: 20px;
      position: absolute;
      right: 40px;
      top: 4px;

      i {
        color: #b7b5b5;
      }
    }

    .SkinObject {
      display: none;
    }

    .SkinObject.show {
      display: block;
    }

    ul.searchSkinObjectPreview,
    .dnnSearchBoxClearText.dnnShow,
    .searchInputContainer a.dnnSearchBoxClearText {
      display: none;
    }
  } /* mobile-search */

  .mobile-search.active {
    margin: 20px 0px 14px 0px;
    height: 100%;
    z-index: 9999;
    height: 100%;
    opacity: 1;
    transition: .3s;
  }
} /* mobile-header */

@media (min-width: 420px) {
  .mobile-header {
    .logo {
      img {
        padding-top: 0px;
      }
    }
  }
}

/** mobile-menu **/
.mobile-menu.main {
  background: $bjc-blue;
  width: 100%;
  z-index: -1;
  position: relative;
  padding-top: 75px;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;

  .container {
    max-width: 485px;
  }

  .mobile-menu-image {
    max-width: 230px;
    padding-top: 13px;
  }

  #close-mobile-menu {
    color: $light-blue;
    font-size: 40px;
    z-index: 99999;
    padding-top: 9px;
  }

  ul.nav {
    z-index: 9999;
    background: $bjc-blue;
    width: 100%;
    height: 100vh;
    margin: 0px;
    margin-top: 75px !important;

      li {
        background: $bjc-blue !important;
        color: #FFFFFF !important;
        border: 0px !important;
        margin-bottom: 45px !important;
        display: block !important;
        width: 100%;

          a:hover {
            background: $bjc-blue !important;

            span {
              color: #FFFFFF !important;
            }
          }

          a {
            color: #FFFFFF !important;
            
            span {
              line-height: 20px !important;
              font-family: $opensans;
              padding: 0px !important;
            }
          }
      }

      li.active {
        
          a {
            color: #FFFFFF !important;
            font-weight: bold;
          }
      }
  }

  .slideout-open .slideout-menu {
    display: block;
    z-index: 999;
    margin: 0px;
  }
} /* mobile-menu.main */

.mobile-menu.main.active {
  z-index: 99999;
  opacity: 1;
}

/* Hide mobile header; display desktop header */
@media (min-width: 992px) {

  .mobile-header {
    display: none;
  }

  .desktop-header {
    display: block;
  }

}

.title-bar {
  padding-top: 15px;

  h2 {
    margin: 0px;
    color: #FFFFFF;
    font-size: 20px;
  }
}

@media (min-width: 992px) {

  .title-bar {

    h2 {
      font-size: 25px;
    }
  }
}

/* Desktop header */

.desktop-header {
  border-bottom: 0px;

  .logo-search {
    padding: 25px 0px;
    background: #FFFFFF;

    .logo-container {
      text-align: left;

      img {
        max-width: 350px;
      }
    } /* logo-container */

    .contact-us-container {
    	padding-right: 20px;
    	padding-top: 8px;
    	color: $bjc-blue;
    	text-align: center;
    }

    .search-container {
      text-align: right;
      max-width: 320px;
      position: relative;
      float: right;
      width: 285px;

      input {
        background: #FFFFFF !important;
        display: block;
        width: 300px;
        border-radius: 20px !important;
        font-size: 14px;
        padding: 0px;
        border: 1px solid #CCC;
        border-radius: 15px;
        padding-right: 40px;
        padding-left: 20px;
        font-size: 13px;
        height: 38px;
        color: #7d7d7d;
      }

      .searchInputContainer a.dnnSearchBoxClearText {
        display: none;
      }

      #dnn_ucHeader_dnnSearchDesktop_cmdSearch {
        font-size: 16px;
        width: 20px;
        position: absolute;
        right: 8px;
        top: 8px;

        i {
          color: #b7b5b5;
        }
      }

      .SkinObject {
        display: none;
      }

      .skinObject.show {
        display: block;
      }
    } /* search-container */

  } /* logo-search */

  .desktop-menu {
    background: $bjc-blue;
    text-align: center;

    #dnnMenu {

      ul.nav {
        width: 100%;

        li:first-of-type {
          display: none;
        }

        li {
          text-align: center;
          border-right: 1px solid #CCC;
          margin-bottom: 0px;

          a {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 30px;
            padding: 16px 28px;
            font-family: $opensans !important;
          }

          a:hover,
          a:active,
          a:focus,
          a:visited {
            background: none !important;
          }
        } /* li */

        li:last-of-type {
          border-right: 0px;
        }

        li.active {

          a {
            // color: #FF7F00;
          }
        }

      } /* ul.nav */

    } /* dnnMenu */

  } /* desktop-menu */
} /* desktop-header */

@media (min-width: 1200px) {
  .desktop-header {

  	.logo-search {

  		.contact-us-container {
  			padding-right: 20px;
  			padding-top: 8px;
  			text-align: right;
  		}
  	}

    .desktop-menu {

      #dnnMenu {

        ul.nav {

          li {

            a {
              font-size: 20px;
              padding: 20px 10px;
            }
          } /* li */

        } /* ul.nav */

      } /* dnnMenu */
    } /* desktop menu */
  }/* desktop-header */

} /* min-width 1200px */

/* sub page secondary mobile menu */
html.slideout-open {
  #panel {
    box-shadow: 3px 0px 23px 0px rgba(112,112,112,0.4);
    -webkit-box-shadow: 3px 0px 23px 0px rgba(112,112,112,0.4);
    -moz-box-shadow: 3px 0px 23px 0px rgba(112,112,112,0.4);
  }
} /* html.slideout-open */

.mobile-secondary-menu {
  display: flex !important;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .project-groups {
  }
}

@media (min-width: 992px) {
  .mobile-secondary-menu {
    opacity: 0;
  }
}

/* home page styles */

.project-groups {

  .group {
    position: relative;
    text-align: center;
    margin-bottom: 15px;

    .icon {
      height: 60px;
      width: 60px;
      margin: 0 auto;
      margin-bottom: 5px;
    }

    .name {
      p {
        margin: 0px;
        font-size: 10px;
        line-height: 15px;
      }
    }

    .category {
      display: none;
    }

  } /* group */

  .group.relative.pg-red .name p {
    color: #F83B3D;
  }

  .group.culture {

    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/culture.svg');
      background-size: cover;
    }
  }

  .group.stabilization {

    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/stabilization.svg');
      background-size: cover;
    }
  }

  .group.pg-blue .name p {
    color: #00BEFF;
  }

  .group.high-reliability {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/high-reliability.svg');
      background-size: cover;
    }
  }

  .group.clinical-integration {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/clinical-integration.svg');
      background-size: cover;
    }
  }

  .group.pg-orange .name p {
    color: #FF7F00;
  }

  .group.team-development {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/team-development.svg');
      background-size: cover;
    }
  }

  .group.medicaid-expansion {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/medicaid-expansion.svg');
      background-size: cover;
    }
  }

  .group.pg-purple .name p {
    color: #9A66CE;
  }

  .group.integrated-framework {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/integrated-framework.svg');
      background-size: cover;
    }
  }

  .group.expanded-capacity {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/expanded-capacity.svg');
      background-size: cover;
    }
  }

  .group.digital-access {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/digital-access.svg');
      background-size: cover;
    }
  }

  .group.pg-green .name p {
    color: #7AC100;
  }

  .group.employee-health-plan {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/employee-health-plan.svg');
      background-size: cover;
    }
  }

  .group.direct-to-employer {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/direct-to-employer.svg');
      background-size: cover;
    }
  }

  .group.gp-moss .name p {
    color: #008082;
  }

  .group.cost-management {
    .icon {
      background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/mobile/cost-management.svg');
      background-size: cover;
    }
  }
} /* project-groups */

/* project-groups destkop styles */
@media (min-width: 992px) {

  /* home page */

  main.home {

    .black-title {
      text-align: center;

      .heading_wrapper {

        h2 {
          margin-bottom: 35px;
          font-size: 40px;
        }
      }
    } /* black-title */

    .project-groups {

      .group {
        -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
        -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
        background: #FFFFFF;
        margin-bottom: 45px;
        padding: 25px 25px;
        max-height: 200px;
        min-height: 200px;

        .full-width-link:hover {
          background: #CCC;
          opacity: .1;
        }

        .icon {
          margin-bottom: 25px;
          height: 80px;
          width: 80px;
        }

        .name {
           p {
            font-size: 16px;
            margin-bottom: 8px;
           }
        }

        .category {
          display: block;
          color: #444;

          p {
            margin-bottom: 0px;
            color: #444;
          }
        }

      } /* group */

      .group.relative.pg-red {
        border-top: 1px solid #F83B3D;
      }
      .group.relative.pg-red .name p {
        color: #F83B3D;
      }

      .group.culture {

        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/culture.svg');
          background-size: cover;
        }
      }

      .group.stabilization {

        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/stabilization.svg');
          background-size: cover;
        }
      }

      .group.pg-blue {
        border-top: 1px solid #00BEFF;
      }
      .group.pg-blue .name p {
        color: #00BEFF;
      }

      .group.high-reliability {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/high-reliability.svg');
          background-size: cover;
        }
      }

      .group.clinical-integration {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/clinical-integration.svg');
          background-size: cover;
        }
      }

      .group.pg-orange {
        border-top: 1px solid #FF7F00;
      }
      .group.pg-orange .name p {
        color: #FF7F00;
      }

      .group.team-development {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/health-disparities.svg');
          background-size: cover;
        }
      }

      .group.medicaid-expansion {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/medicaid-expansion.svg');
          background-size: cover;
        }
      }

      .group.pg-purple {
        border-top: 1px solid #9A66CE;
      }
      .group.pg-purple .name p {
        color: #9A66CE;
      }

      .group.integrated-framework {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/integrated-framework.svg');
          background-size: cover;
        }
      }

      .group.expanded-capacity {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/expanded-capacity.svg');
          background-size: cover;
        }
      }

      .group.digital-access {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/digital-access.svg');
          background-size: cover;
        }
      }

      .group.pg-green {
        border-top: 1px solid #7AC100;
      }
      .group.pg-green .name p {
        color: #7AC100;
      }

      .group.employee-health-plan {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/employee-health-plan.svg');
          background-size: cover;
        }
      }

      .group.direct-to-employer {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/direct-to-employer.svg');
          background-size: cover;
        }
      }

      .group.gp-moss {
        border-top: 1px solid #008082;
      }
      .group.gp-moss .name p {
        color: #008082;
      }

      .group.cost-management {
        .icon {
          background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/cost-management.svg');
          background-size: cover;
          margin-bottom: 20px;
        }
      }

    } /* project-groups */

  } /* main.home */

} /* min-width: 992px */

@media (min-width: 1200px) {
  main.home .project-groups .group {
      padding: 30px 25px;
      max-height: 215px;
      min-height: 215px;
  }

  main.home .project-groups .group.cost-management {
  }
}

/* home page content styles */
main.home {

  .message-wrapper {
    background: #FFFFFF;
    padding-top: 50px;
    margin-bottom: 50px;
  }

  .message {
    margin-bottom: 50px;

    h2 {
      margin-bottom: 40px;
    }

    .message-container {
      background: #FFFFFF;
      border-radius: 8px;
      position: relative;

      .image {
        display: none;
      }

      .subtitle {
        margin-top: 5px;
        line-height: 14px;
        font-size: 12px;
        font-style: italic;
        padding-left: 5px;
        padding-right: 5px;
      }

      .header-text {
        margin-bottom: 35px;

        hr {
          display: block;
          border: 3px solid #00529B;
          margin-top: 0px;
          margin-bottom: 20px;
          max-width: 65px;
        }

        h3 {
          font-size: 19px;

          a {
            color: #1A051D;
            font-size: 30px;
          }
        }

        span {
          font-size: 15px;
          color: #C8C8D1;
        }
      } /* header-text */

      .content {
        p.see-all {
          margin-bottom: 0px;
        }
      } /* content */

    } /* message-container */

  } /* message */

  .pm-list {
    h2 {
      margin-bottom: 20px;
    }  
  }

} /* main.home mobile*/

@media (min-width: 992px) {

  main.home {
    margin-top: 25px;

    .two-col-layout {
      position: relative;
      padding: 100px 0px;

      .desktop-colored-backgrounds {

        .gray {
          padding: 50px 0px;
          width: 50%;
          float: left;
          background: #E1DFE6;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 100%;
        }

        .blue {
          width: 50%;
          float: right;
          background: #00529B;
          position: absolute;
          top: 0px;
          left: 50%;
          height: 100%;
        }

      } /* desltop-colored-backgrounds */
    } /* 2-col-layout */

    .message-wrapper {
      background: #FFFFFF;
      padding: 70px 0px;
    }

    .message {
      margin-bottom: 0px;

      h2 {
        display: none;
      }

      .message-container {
        background: transparent;
        border-radius: 0px;
        position: relative;
        padding: 0px;

        .image {
          display: block;
          margin-top: 40px;

          img {
            width: 100%;
            height: auto;
          }
        }

        .header-text {
          margin-top: 40px;

          hr {
            display: block;
            border: 3px solid #00529B;
            margin-top: 0px;
            margin-bottom: 20px;
            max-width: 65px;
          }

          h3 {
            font-size: 29px;
            line-height: 34px;
          }

          span {
            font-size: 16px;
          }
        } /* header-text */

        .content {

        } /* content */

        .header-text,
        .content {
          padding-left: 30px;
        }

      } /* message-container */

    } /* message */

    .pm-container {

      h2 {
        color: #FFFFFF;
        margin-bottom: 40px;
      }
    }
  } /* main.home */
} /* min-width 992px */

@media (min-width: 1200px) {

  main.home {

    .message {
    }

    .pm-container {
      padding-left: 100px;
    }

    .header-text,
    .content {
    }
  } /* main.home */

} /* min width 1200px */

/* project manager EasyDNNNews blocks */
.project-manager {
  border-radius: 8px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;

  .image {
    max-width: 150px;
    float: left;

    img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    padding: 33px 0px;
    width: calc(100% - 175px);
    float: right;
  }

  .pm-name {

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 5px;
      margin-top: 0px;
    }
  } /* pm-name */

  .pm-exec-title {
    margin-bottom: 15px;
      span {
        font-style: italic;
        font-size: 14px;
      }
  } /* pm-exec-title */

  .pm-title {
    border-left: 2px solid $bjc-blue;
    padding-left: 8px;

    h4 {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }
  }

} /* project-manager */

@media (min-width: 960px) {

  .project-manager {
    border-radius: 0px;

    .content {
      padding: 32px 0px;
    }

    .pm-name {

      h3 {
        color: $bjc-blue;
        font-size: 26px;
        margin-bottom: 7px;
      }
    }

    .pm-exec-title {
      span {
        color: $bjc-blue;
      }
    }

    .pm-title {
      border-left: 2px solid $bjc-blue;

      h4 {
        font-size: 14px;
      }
    }

  } /* project-manager */

  main.home {

    .project-manager {
      border-radius: 0px;
      padding: 25px 20px;
      background: #065EAB;
      margin-bottom: 15px;
      color: #FFFFFF !important;

      .pm-name {
        h3 {
          color: #FFFFFF;
        }
      } /* pm-name */

      .pm-title {
        border-left: 2px solid #FFFFFF;
        h4 {
          color: #FFFFFF;
        }
      }
    } /* project-manager */
  }
}


/* containers */

.module-container {
  margin-bottom: 25px;

  .black-title  h2 {
    color: $black;
    font-size: 17px;
  }
}

.updates-container {

  h2 {
    margin-bottom: 0px;
  }

  .view-all {
    text-align: right !important;
    margin-bottom: 30px;

    a {
      color: $bjc-blue;
      border: 1px solid $bjc-blue;
      border-radius: 12px;
      background: transparent;
      text-align: center;
      padding: 2px 10px;
      text-decoration: none !important;
      font-size: 14px;
    }
  }

} /* updates-container */

@media (min-width: 992px) {

    .black-title {

      h2 {
        margin-bottom: 25px;
        font-size: 37px;
      }
    }

    .updates-container {

      h2 {
        margin-bottom: 0px;
      }

      .view-all {
        text-align: right;

        a {
          color: #FFFFFF;
          background: $bjc-blue;
          text-decoration: none !important;
          padding: 3px 14px;
        }
      }

    } /* updates-container */
} /* min-width 992px */

/* sub page */

main.sub-page {

  .sub-header {
    margin-bottom: 15px;

    .page-title {

      h2 {
        font-size: 17px;
        text-align: left;
        font-weight: 600;
        color: #43425D;
      }
    }

    .secondary-mobile-menu-container {
      text-align: right;
      line-height: 19px;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      display: flex;
      display: -webkit-box; 
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex; 
      max-width: 95px;
      float: right;
      margin-top: 15px;

      span,
      i {
        display: inline-block;
      }

      span {
        text-transform: uppercase;
        padding-right: 5px;
        font-size: 10px;
      }

      i {
        font-size: 20px;
      }
      
    } /* secondary-mobile-menu-container */
  } /* sub-header */

  .contentPane {
    border-radius: 8px;
    background: #FFFFFF;
    padding: 20px;
    -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
    -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    margin-bottom: 35px;

    * {
      text-align: left;
    }

    h2 {
      font-size: 22px;
      line-height: 32px;
      color: $bjc-blue;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .newsPane .DnnModule {
    margin-bottom: 60px;
  }

  .project-groups.sidebar {

    .group {
      -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
      -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      background: #FFFFFF;
      margin-bottom: 10px;
      padding-top: 13px;
      padding-bottom: 7px;
      padding-right: 5px;
      min-height: 56px;

      .full-width-link:hover {
        background: #CCC;
        opacity: .1;
      }

      .icon {
        height: 18px;
        width: 18px;
        float: left;
        margin-left: 20px;
        margin-top: 6px;
      }

      .name {
         p {
          font-size: 16px;
          margin-bottom: 8px;
          text-align: left;
          color: #333333 !important;
          margin-left: 20px;
          margin-bottom: 0px;
         }
      }

      .category {
        display: block;
        color: #444;


        p {
          margin-bottom: 0px;
          color: #444;
          text-align: left;
          margin-left: 20px;
          margin-bottom: 0px;
        }
      }

    } /* group */

    .group.relative.pg-red {
      border-left: 4px solid #F83B3D;
    }
    .group.relative.pg-red .name p {
      color: #F83B3D;
    }

    .group.culture {

      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/culture.svg');
        background-size: cover;
      }
    }

    .group.stabilization {

      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/stabilization.svg');
        background-size: cover;
      }
    }

    .group.pg-blue {
      border-left: 4px solid #00BEFF;
    }
    .group.pg-blue .name p {
      color: #00BEFF;
    }

    .group.high-reliability {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/high-reliability.svg');
        background-size: cover;
      }
    }

    .group.clinical-integration {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/clinical-integration.svg');
        background-size: cover;
      }
    }

    .group.pg-orange {
      border-left: 4px solid #FF7F00;
    }
    .group.pg-orange .name p {
      color: #FF7F00;
    }

    .group.team-development {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/health-disparities.svg');
        background-size: cover;
      }
    }

    .group.medicaid-expansion {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/medicaid-expansion.svg');
        background-size: cover;
      }
    }

    .group.pg-purple {
      border-left: 4px solid #9A66CE;
    }
    .group.pg-purple .name p {
      color: #9A66CE;
    }

    .group.integrated-framework {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/integrated-framework.svg');
        background-size: cover;
      }
    }

    .group.expanded-capacity {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/expanded-capacity.svg');
        background-size: cover;
      }
    }

    .group.digital-access {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/digital-access.svg');
        background-size: cover;
      }
    }

    .group.pg-green {
      border-left: 4px solid #7AC100;
    }
    .group.pg-green .name p {
      color: #7AC100;
    }

    .group.employee-health-plan {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/employee-health-plan.svg');
        background-size: cover;
      }
    }

    .group.direct-to-employer {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/direct-to-employer.svg');
        background-size: cover;
      }
    }

    .group.gp-moss {
      border-left: 4px solid #008082;
    }
    .group.gp-moss .name p {
      color: #008082;
    }

    .group.cost-management {
      .icon {
        background-image: url('/Portals/_default/Skins/integriTheme/Images/icons/desktop/cost-management.svg');
        background-size: cover;
      }
    }

  } /* project-groups sidebar */

} /* main.sub-page */

@media (max-width: 991px) {
  .sidebarContentPane {
    display: none;
  }
}

/* desktop sub-page */
@media (min-width: 992px) {
  main.sub-page {

    .sub-header {

      .page-title {

        h2 {
          font-size: 24px;
          font-weight: normal;
          margin-bottom: 38px;
        }
      }

      .secondary-mobile-menu-container {
        display: none;
      }
    } /* sub-header */

    .contentPane {
      border-radius: 0px;
      padding: 20px 35px;
      margin-bottom: 80px;

      h2 {
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 30px;
      }

      p, ul, ol {
        color: #444;
      }
    } /* contentPane */
    
  } /* main.sub-page */

} /* min-width: 992px */


.news-and-resources {

  main {
  }

  .hero {
    margin-bottom: 75px;
    background-image: url('/Portals/_default/Skins/integriTheme/images/hero.png');
    background-size: cover;
    background-position: center;
    height: 120px;
  }

  .banner-image {
    margin-bottom: 25px;

    img {
      width: 100%; 
      height: auto;
    }
  }

  .black-title {

    h2 {
      text-align: center;
    }
  } /* black-title */

  .article_pager {
    text-align: right;
    margin-top: 60px;

    a.page {
      border: 1px solid #E8E9EC;
      color: $black;
      margin-right: 10px;
      background: #FFFFFF;
      border-radius: 7px;
      padding: 7px 15px;
    }

    a.active.page {
      color: #FFFFFF;
      background: $bjc-blue;
      border: 1px solid $bjc-blue;
    }

    a.page:last-of-type {
      margin-right: 0px;
    }

  } /* article_pager */

} /* news-and-resources */

@media (min-width: 992px) {
  .news-and-resources {
    margin-top: -75px;

    .hero {
      margin-bottom: 75px;
      background-image: url('/Portals/_default/Skins/integriTheme/images/hero.png');
      background-size: cover;
      background-position: center;
      height: 400px;
      margin-top: 75px;
    }

    .banner-image {
      margin-top: 75px;
      margin-bottom: 75px;

      img {
      }
    }
  }
}

/* updates blog posts */

.updates {
  background: #FFFFFF;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
  -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);

  .content {
    padding: 20px 20px 0px 20px;
  }

  a {
    text-decoration: none !important;
  }

  h2 {
    margin-bottom: 25px;
    text-align: left !important;
  }

  h2 a {
    color: $black !important;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 25px;
    text-align: left !important;
  }

  .summary {

  }

  .summary p {
    color: #666666;
    font-size: 14px;
    line-height: 17px;

    a {
      color: $bjc-blue !important; 
    }
  }

  .learn-more {
    text-align: right;
    margin-bottom: 15px;
    a {
        color: $bjc-blue !important;
        text-align: right;
      }
  } /* learn-more */

  .category {
    padding: 10px 12px;
    background: $bjc-blue;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    a {
      color: #FFFFFF;
    }
  }

} /* updates */

.updates.home {
  .category {
    padding: 10px 12px;
    background: $bjc-blue;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    a {
      color: #FFFFFF;
    }
  }
}

@media (min-width: 992px) {

  .updates {
    border-radius: 0px;

    .content {
      padding: 25px 25px 0px 25px;

      h2, h2 a {
        font-size: 24px;
      }

      .summary p {

      }

      .learn-more {
        text-align: left;
      }
    }

    .category {
      text-align: right;
      padding-bottom: 25px;
      padding-right: 25px;
      background: #FFFFFF;

      a {
        border-radius: 18px;
        color: $bjc-blue;
        border: 1px solid $bjc-blue;
        padding: 6px 10px;
      }

      a:hover {
        background: $bjc-blue;
        color: #FFFFFF !important;
      }
    }
  }

  .article.in_list.box.big.edn_articleWrapper.span.updates.home {
      margin-bottom: 30px !important;
  }

  .updates.home {
    width: calc(50% - 30px);
    margin-bottom: 30px;
    border-radius: 0px;

    .admin_actions {

      .admin_action.edit {
        background-image: none;
        background: none;
        color: #FFFFFF !important;
      }
    }

    .category {
      background: transparent;
      margin-bottom: 15px;

      a {
        color: $bjc-blue;
      }
    }
  }

  .updates.home:nth-child(even) {
    margin-right: 30px;
  }

} /* min-width 992px */

/* updates category dropdown */

.category-dropdown-container {
  max-width: 480px;
  margin: 0 auto;
  margin-top: 30px;
}

.category_menu {

  .news-filter {
    background: $bjc-blue;
    font-size: 14px;
    color: #FFFFFF;
    padding: 9px;
    text-align: center;
    margin-bottom: 5px;
  }

  .categories-dropdown {
  background: #FFFFFF;
  position: relative;

    .active-category {
      min-height: 40px;
      background: #FFFFFF;
      min-width: 200px;
      border: 1px solid #BCE0FD;
      position: relative;
      padding-right: 48px;
      padding-left: 10px;
      display: flex;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      span  {
        padding-top: 5px;
        padding-bottom: 5px;
      }

    } /* active-category */

    .active-category::after {
      content: '\f107';
      font-family: "Font Awesome 5 Pro";
      position: absolute;
      right: 10px;
      top: 8px;
      font-size: 18px;
      background: #FFFFFF;
      /* border-radius: 8px; */
      -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      padding: 2px 6px;
      line-height: 18px;
      color: $bjc-blue;
    }

    ul.edn_category_menu_wrapper {
      margin-left: 0px;
      display: none;
      background: #FFFFFF;
      -webkit-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);;
      -moz-box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      width: 100%;

      li {
        list-style-type: none;
        margin-bottom: 18px;

        a {
          color: $black;
          display: block;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 16px;
        }
      }
    } /* ul */

    ul.edn_category_menu_wrapper.open {
      display: block;
      position: absolute;
      top: 40px;
      padding-top: 18px;
      border: 1px solid #BCE0FD;
      border-top: 0px;
    }

  } /* categories-dropdown */

  .clear-category {
    margin-top: 15px;
    text-align: right;

    a {
      color: #FFFFFF;
      background: $bjc-blue;
      padding: 5px 11px;
      font-size: 14px;
      border-radius: 13px;
    }
  } /* clear-category */

} /* category_menu */

@media (min-width: 992px) {
  .category-dropdown-container {
    max-width: 620px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 25px;
    display: -ms-flexbox;
  }

  .category_menu {

    .news-filter {
      display: inline-block;
      margin-bottom: 0px;
      height: 50px;
      line-height: 33px;
      text-align: center;
    }

    .categories-dropdown {
      display: inline-block;
      margin-right: 15px;
      min-width: 448px;
      cursor: pointer;
      display: -ms-flexbox;

      .active-category {
        min-height: 50px;
        padding-left: 16px;
        width: 100%;

        span {
          font-size: 17px;
        }
      }

      .active-category::after {
        top: 13px;
      }

      ul.edn_category_menu_wrapper {

        li {
          margin-bottom: 5px;
          a {
            font-size: 17px;
            padding: 15px 25px;
          }

          a:hover {
            background-color: #BCE0FD;
          }
        }
      }

      ul.edn_category_menu_wrapper.open {
        top: 50px;
      }

    }

    .clear-category {
      display: inline-block;

      a {
        font-size: 15px;
      }

    } /* categories-dropdown */
  } /* category_menu */

} /* min-width 992px */


/* single post page styles */
.single-post {


}


.back-to-updates {
  display: none;
}


/* stock bjc footer */
.site--footer {
   background-color: #00529b; 
   padding-bottom: 30px;
}

.site--footer .social-links a {
   color: #ffffff;
   font-size: 36px;
   padding-right: 30px;

}

.site--footer .social-links a:hover i {
  opacity: .9;
}

.site--footer .identity--connect {
   text-align: center;
   padding-top: 4px;
   background-color:  #2D71B1;
   height: 90px;
}

.site--footer .identity--address {
   text-align: center;
}

.site--footer .identity--logo {
   padding-top: 65px;
   padding-bottom: 25px;

   img {
    max-width: 235px;
   }
}

.site--footer .address {
   font-family: 'Open Sans';
   font-size: 15px;
}

.site--footer .address a {
   color: #ffffff;
   text-decoration: none;
   font-size: 16px;
}

.site--footer .address p {
   color: #ffffff !important;
   font-size: 12px !important;
   line-height: 18px;
}

.row13 {
   background: #00529b;
}

.sitemapRow {
   text-align: center;
}

.sitemapRow .container .sitemap-wrapper {
  text-align: center;
}

ul.sitemapMenu {
   border-top: 1px solid #4283b9;
   text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
   margin: 0 auto;
   padding: 0 40px;
   display: table;
}

ul.sitemapMenu li {
  padding: 15px 12px 15px 12px;
  display: table-cell;
  text-align: center;
}

ul.sitemapMenu li a {
  font-size: 12px;
  color: #ffffff;
  text-decoration: none;
}

ul.sitemapMenu li a:hover {
   color: #a5c3de;
}

@media (max-width: 767px) {
  .row13 .sitemapMenu {
      display: block;
      text-align: center;
      padding: 10px 0;
  }
  .row13 .sitemapMenu li {
      display: inline-block;
      padding: 4px 10px;
  }
}


/* slideout.js styles */
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 295px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: $background-gray;
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

/** ActionForms input styles **/

.DnnModule-ActionForm {

  label {
    margin-bottom: 5px !important;
  }

  .radio,
  .checkbox  {
    margin-left: 0px !important;
  }

  .form-control {
    border-radius: 0px !important;
  }

  input[type=radio],
  input[type=checkbox] {
    margin-top: 3px;
  }

  button {
    background-color: #8bb4ce !important;
    border: 1px solid #8bb4ce !important;
    border-radius: 0px !important;
    padding: 8px 20px !important;
  }

} /* avt-action-form */

/** EasyDNNNews controls styles **/
.admin_actions {
  background-color: $bjc-blue;
  padding: 10px 0px;
}

 .user_dashboard {
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  padding: 4px 6px 1px;
  margin: 0 0 20px;
  overflow: hidden;
  background-color: #ebebeb;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
 .user_dashboard:hover {
  background-color: #f2f2f2;
  border-color: #000;
}
 .user_dashboard a {
  display: block;
  float: left;
  font-size: 11px;
  font-family: Arial;
  font-weight: bold;
  padding: 0 0 0 35px;
  height: 25px;
  line-height: 24px;
  margin: 0 10px 0 0;
  color: #5C5C5C !important;
  text-decoration: none !important;
}
 .user_dashboard a.add_article {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/green_plus.png") no-repeat;
  padding-left: 25px;
}
 .user_dashboard a.article_manager {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/papers.png") no-repeat 0 0;
  padding-left: 28px;
}
 .user_dashboard a.event_manager {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/event_manager.png") no-repeat 0 1px;
  padding-left: 35px;
}
 .user_dashboard a.approve_articles {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/approve_articles.png") no-repeat 0 1px;
  padding-left: 22px;
}
 .user_dashboard a.approve_comments {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/approve_comments.png") no-repeat 0 2px;
  padding-left: 22px;
}
 .user_dashboard a.category_manager {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/categories.png") no-repeat 0 2px;
  padding-left: 22px;
}
 .user_dashboard a.dashboard {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/monitor.png") no-repeat 0 3px;
  padding-left: 24px;
}
 .user_dashboard a.settings {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/settings.png") no-repeat 0 1px;
  padding-left: 24px;
}
 .user_dashboard a.author_profile {
  background: url("/DesktopModules/EasyDNNNews/Templates/_default/BJCStrategicPriorities/assets/images/common/user_dashboard/profile.png") no-repeat 0 1px;
  padding-left: 18px;
}
 .user_dashboard a:hover {
  color: #000 !important;
  text-decoration: none !important;
}

 .edn_article.edn_articleDetails .edn_articleTitle {
  margin-bottom: 20px;
}
 .edn_article.edn_articleDetails .edn_articleTitle + .edn_articleSubTitle {
  margin-top: -15px;
}
 .edn_article.edn_articleDetails .edn_articleSubTitle {
  margin-bottom: 20px;
}
 .edn_article.edn_articleDetails .edn_metaDetails {
  margin-bottom: 20px;
}

.edn_adminActions > a.edit,
.admin_action.edit {
  color: #FFFFFF;
  border-color: #e78d00;
  margin-left: 15px;
}
.edn_adminActions > a.edit:before,
.admin_action.edit:before {
  font-family: $fontawesome;
  content: '\f044';
  padding-right: 5px;
}

/** HIDES MODULE TITLE RESULTS FROM SEARCH PAGE **/

/** IE CSS fixes **/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .category_menu .categories-dropdown .active-category span {
      padding-top: 6px;
     }
}




/**** COVID 19 STYLES ******/

.search-container {
  display: none;
}


/* home */
.home {
  .contentPane {
    margin-bottom: 30px;
  }

  #dnn_ResourcesFeedTitle {
    padding-top: 20px;
  }

  #dnn_UpdatesFeed {
    background: #FFFFFF;
    padding: 20px;
  }

  .resource {
    border-top: 3px solid $purple;
    padding: 15px 30px 10px 30px;
    background: #FFFFFF;
    text-align: center;
    margin-bottom: 20px;
    min-height: 175px;
    -webkit-box-shadow: 0px 2px 5px rgba(0, 32, 61, 0.1) !important;
    box-shadow: 0px 2px 5px rgba(0, 32, 61, 0.1) !important;
    position: relative;

    .full-width-link {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 999;
    }

    .icon {
      background: $bjc-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 115px;
      min-height: 95px;
      margin: 0 auto;
      margin-bottom: 10px;

      i {
        color: #FFFFFF;
        font-size: 55px;
      }
    }

    .link-title {
      text-align: center;
      font-weight: 600;
      color: #000000;
      font-size: 15px;
    }

  } /* resource */

  .resource.vs,
  .resource.spreadsheet {

    .icon {

      i:before {
        color: #222529;
      }

      i:after {
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }

  .covid19-updates {
    margin-top: 30px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;

      a {
        color: #222529;
      }
    }

    .summary p {
      display: inline;
      line-height: 1.75
    }

    .summary-content {
      line-height: 1.8;
    }

    .read-more {
      color: #FFFFFF;
      background: $purple;
      padding: 1px 5px;
      display: block;
      max-width: 52px;
      margin-top: 5px;
    }
  }

  .article_pager {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 10px;
    display: none;

    .page {
      padding: 8px 14px;
      color: #222529;
      margin: 0px 2px;
    }

    .page.active {
      background: $purple;
      color: #FFFFFF;
    }

    .next,
    .last {
      font-size: 0px;
      display: none;
    }

    .next:before {
      content: ">";
      font-size: 15px;
    }

    .last:before {
      content: "<";
      font-size: 15px;
    }
  }

  .video-feed {
    background: #FFFFFF;
    padding: 60px 0px;
  }

} /* home */

#dnn_WarningMessage {
  background: #FFFFFF;
  padding: 20px 40px 20px 20px;

  .icon {
    text-align: center;

    i {
      color: #f8cc46;
      font-size: 75px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .message {

  }

} /* dnnWarningMessage */

@media screen and (min-width: 992px) {

  .home {

    #dnn_WarningMessage {

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 165px;

        i {
          margin-bottom: 0px;
          font-size: 105px;
        }
      } /* icon */

    } /* dnn warning message */

    .no-padding-left {
      padding-left: 0px;
    }

    .no-padding-right {
      padding-right: 0px;
    }

  } /* home */

} /* min-width 992px */

main.home {
  margin-top: 25px;
}


.inner-page {
  margin-top: 25px;

  #dnn_MainContent {
    padding: 30px !important;
    background: #FFFFFF;
  }

  .contentPane {
    margin-bottom: 30px;
  }

}

.breadcrumbs {
  background: #FFFFFF;
  padding: 10px 20px;

  a {
    color: $purple;
    text-transform: uppercase;
  }
}

.single-post-page .contentPane {
  padding: 30px;
  background: #FFFFFF;
  margin-bottom: 25px;
}

.single-post-page .contentPane.breadcrumbs {
    padding: 10px 20px;
}

.single-post-page {
  .article_pager {
    display: none;
  }
}

.cbp-l-grid-projects-title {
  font-size: 20px;
}

.edn_articleTitle {
  color: #1A051D !important;
}

.video {
  margin-bottom: 30px;
}

.video-title {
  font-size: 20px;
  text-align: center;
  color: #1A051D !important;
  margin-top: 10px;
}

.video-description {
  color: rgb(136, 136, 136);
  font-style: italic;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
}

.publish-date {

  span {
    color: #1A051D !important;
    font-size: 14px !important;
  }

  hr {
    border: .5px solid #1A051D;
    max-width: 50px;
    margin: 5px 0px;
    margin-bottom: 30px;
  }
}

/* Containers */
.fancy {
  h2 {
    margin-top: 10px;
  }
  hr {
    border: .5px solid $purple;
    margin-bottom: 0px;
  }
}

.module-container {
  margin-bottom: 0px;
}


main {
  margin-bottom: 0px;
}


#mainContent-inner {
  padding: 0px;
}

#M495EasyDNNGallery table.EDGlightbox.EDGresponsive {
  max-width: 100% !important;
}

.cbp-l-grid-projects .cbp-caption {
  height: 66% !important;
}

.cbp-wrapper {
  margin-top: 30px;
}

.cbp-l-grid-projects-desc span {
  font-size: 14px;
}

.single-post-page {
  margin-top: 25px;
}


.add_comment {

  h3 {
    color: $bjc-blue;
  }
}

.add_article_box,
.eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div,
.eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td input[type='text'], .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td input[type='text']:hover, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td input[type='text']:focus, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td textarea, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td textarea:hover, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment > div table td textarea:focus,
.eds_subCollection_news.eds_news_Ozone .edn_readMoreButton.edn_readMore, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment .add_article_box .submit,
.eds_subCollection_news.eds_news_Ozone .article_comments .num_of_comment {
  border: 1px solid $bjc-blue !important;
}

.eds_subCollection_news.eds_news_Ozone .edn_readMoreButton.edn_readMore:hover, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment .add_article_box .submit:hover {
  background: $bjc-blue;
}

.eds_subCollection_news.eds_news_Ozone .edn_readMoreButton.edn_readMore, .eds_subCollection_news.eds_news_Ozone .article_comments .add_comment .add_article_box .submit {
  color: $bjc-blue;
}

.eds_subCollection_news.eds_news_Ozone .article_comments .num_of_comment a,
.eds_subCollection_news.eds_news_Ozone a {
  color: $bjc-blue !important;
}


.inner-page {

  .covid19-updates {
    margin-top: 50px;

    h3 {
      font-size: 18px;

      a {
        color: #222529;
        font-weight: 600;
      }
    }

    p {
      margin-bottom: 10px;
    }
  }

}



.read-more {
  color: #FFFFFF !important;
  background: $purple;
  padding: 1px 5px;
  display: block;
  max-width: 52px;
  margin-top: 5px;
}

.view-more-posts {
  color: #FFFFFF !important;
  background: $purple;
  padding: 6px 15px;
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;
  display: block;
  max-width: 130px;
}

.video-container {
position: relative;
padding-bottom: 56.25%;
padding-top: 30px; height: 0; overflow: hidden;
}
 
.video-container iframe,
.video-container object,
.video-container embed {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}


.SubHead img {
  margin-top: -9px;
}

.SubHead {
  font-weight: bold;
}